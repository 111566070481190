/* eslint-disable */
export default {
  DATABASE_TAGS: [
    { id: 0, name: '1. BObject' },
    { id: 1, name: '2. Category' },
    { id: 2, name: '3. Channel or Department' },
  ],
  DETAIL_TAGS: [
    { id: 0, name: 'Details' },
    { id: 1, name: 'Annotation' },
    { id: 2, name: 'Raw Data' },
  ],

  data_object: {
    form: 'tagFieldArrayForm1_1',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Describes the object with links to underlying content',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Object Tags' },
      { headerName: 'Describes the object with links to underlying content' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_organization: {
    form: 'tagFieldArrayForm1_2',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Organizes the object',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Organization Tags' },
      { headerName: 'Organizes the object' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_metric: {
    form: 'tagFieldArrayForm1_3',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Metrics for the object',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Metric Tags' },
      { headerName: 'Metrics for the object' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_video: {
    form: 'tagFieldArrayForm1_4',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Video specific tag',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Video Tags' },
      { headerName: 'Video specific tag' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_audio: {
    form: 'tagFieldArrayForm1_5',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Audio specific tag',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Audio Tags' },
      { headerName: 'Audio specific tag' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_image: {
    form: 'tagFieldArrayForm1_6',
    disabled: true,
    hideSaveButton: true,
    action: false,
    info: 'Image specific tag',
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [
      { headerName: 'Image Tags' },
      { headerName: 'Image specific tag' },
    ],
    fieldArrayRows: ['tagName', 'tagDesc'],
  },

  data_application: {
    form: 'applicationForm',
    action: true,
    // disabled: false,
    colSpan: [{ xs: 3 }, { xs: 7 }, { xs: 2 }],
    header: [
      { headerName: 'Application Tags' },
      { headerName: 'Tag Comments' },
      { headerName: 'Action' },
    ],
    hideSaveButton: false,
    fieldArrayRows: ['tagName', 'tagDesc'],
    emptyData: { tagName: '', tagDesc: '' },
  },

  dataCategory: {
    form: 'categoryForm',
    action: true,
    colSpan: [{ xs: 3 }, { xs: 7 }, { xs: 2 }],
    header: [
      { headerName: 'Category Name' },
      { headerName: 'Category Description' },
      { headerName: 'Action' },
    ],
    hideSaveButton: false,
    fieldArrayRows: ['categoryName', 'categoryDesc'],
    emptyData: { categoryName: '', categoryDesc: '' },
  },

  dataChannel: {
    form: 'channelForm',
    action: true,
    colSpan: [{ xs: 3 }, { xs: 7 }, { xs: 2 }],
    header: [
      { headerName: 'Channel Name' },
      { headerName: 'Channel Description' },
      { headerName: 'Action' },
    ],
    hideSaveButton: false,
    fieldArrayRows: ['channelName', 'channelDesc'],
    emptyData: { channelName: '', channelDesc: '' },
    isLastTab: true,
  },

  data_applicationTags: {
    form: 'applicationTags',
    action: false,
    disabled: false,
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [{ headerName: 'Fields' }, { headerName: 'Values' }],
    hideSaveButton: true,
    fieldArrayRows: ['key', 'value'],
    emptyData: { tagName: '', tagDesc: '' },
  },

  data_annotationTags: {
    form: 'annotationTags',
    action: false,
    disabled: false,
    colSpan: [{ xs: 3 }, { xs: 9 }],
    header: [{ headerName: 'Key' }, { headerName: 'Values' }],
    hideSaveButton: true,
    fieldArrayRows: ['type', 'value'],
    emptyData: { tagName: '', tagDesc: '' },
  },

  // UPLOAD_TYPES: ['Excel', 'Local', 'S3', 'GCS'],
  UPLOAD_TYPES: ['Excel', 'PDF', 'URLs'],
  TEMPLATE_TYPES: ['All', 'Video', 'Audio', 'Image', 'Text'],

  SIDE_PANEL: {
    Heading: 'Welcome to BerryDB!',
    Content: [{ Content: 'The database for unstructured data and AI apps' }],
    bodyContent: [
      {
        heading: 'Getting started:',
        link: 'https://berrydb.io/blog/getting-started',
        content:
          'Getting started with BerryDB is easy. This article describes the core features and how to use them ',
      },
      {
        heading: 'Why BerryDB: ',
        link: 'https://berrydb.io/blog/why-berrydb',
        content:
          'BerryDB natively manages unstructured data. It is different from traditional RDBMS or NoSQL database. This article explains how it is different and why you need it.',
      },
      {
        heading: 'BerryDB architecture',
        link: 'https://berrydb.io/blog/berrydb-architecture',
        content:
          'BerryDB is a native JSON database that is designed to horizontally scale data, query and index nodes. This article describes the architecture.',
      },
      {
        heading: 'BerryDB API and SDK',
        link: 'https://docs.berrydb.io/python-sdk',
        content:
          'A guide for developers on how to integrate and interact with BerryDB, through API documentation, code examples and best practices.',
      },
    ],
    moreInfo: {
      text: 'For more information and articles, visit, ',
      link: 'http://berrydb.io',
    },
  },

  DATABASE_DETAILS: {
    heading: 'Create a database',
    subHeading: '',
    subContent: '',
    contentOne: `Create a database by entering the database name, description and select the schema from the drop down menu below:`,
    contentTwo: ``,
    thumbnailNote: `Upload a thumbnail that visually represents this Database. If its not specified the
    first object populated is used to represent the Database as a whole.`,
  },
};
