/* eslint-disable */
export const CREATE_DATABASE_REQUESTED = 'profile/CREATE_DATABASE_REQUESTED';
export const CREATE_DATABASE_SUCCESS = 'profile/CREATE_DATABASE_SUCCESS';
export const CREATE_DATABASE_FAILURE = 'profile/CREATE_DATABASE_FAILURE';

export const DATABASE_LIST_REQUESTED = 'berrydb/DATABASE_LIST_REQUESTED';
export const DATABASE_LIST_SUCCESS = 'berrydb/DATABASE_LIST_SUCCESS';
export const DATABASE_LIST_SUCCESS_NO_UPDATE =
  'berrydb/DATABASE_LIST_SUCCESS_NO_UPDATE';
export const DATABASE_LIST_FAILURE = 'berrydb/DATABASE_LIST_FAILURE';

export const DATABASE_DELETE_REQUESTED = 'profile/DATABASE_DELETE_REQUESTED';
export const DATABASE_DELETE_SUCCESS = 'profile/DATABASE_DELETE_SUCCESS';
export const DATABASE_DELETE_FAILURE = 'profile/DATABASE_DELETE_FAILURE';

export const SCHEMA_REQUESTED = 'profile/SCHEMA_REQUESTED';
export const SCHEMA_SUCCESS = 'profile/SCHEMA_SUCCESS';
export const SCHEMA_FAILURE = 'profile/SCHEMA_FAILURE';

export const SCHEMA_DELETE_REQUESTED = 'profile/SCHEMA_DELETE_REQUESTED';
export const SCHEMA_DELETE_SUCCESS = 'profile/SCHEMA_DELETE_SUCCESS';
export const SCHEMA_DELETE_FAILURE = 'profile/SCHEMA_DELETE_FAILURE';

export const FILTERDATA_REQUESTED = 'berrydb/FILTERDATA_REQUESTED';
export const FILTERDATA_SUCCESS = 'berrydb/FILTERDATA_SUCCESS';
export const FILTERDATA_FAILURE = 'berrydb/FILTERDATA_FAILURE';

export const EMBEDDED_DATABASE_LIST = 'EMBEDDED_DATABASE_LIST';
export const EMBEDDED_DATABASE_LIST_FAILURE = 'EMBEDDED_DATABASE_LIST_FAILURE';

const initialState = {
  file: null,
  databaseList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DATABASE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CREATE_DATABASE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CREATE_DATABASE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case DATABASE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DATABASE_LIST_SUCCESS_NO_UPDATE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DATABASE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        databaseList: action.result,
      };
    }
    case DATABASE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case DATABASE_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DATABASE_DELETE_SUCCESS: {
      const newDBList =
        state.databaseList &&
          state.databaseList.responseList &&
          state.databaseList.responseList.length
          ? {
            ...state.databaseList,
            responseList: state.databaseList.responseList.filter(
              db => db.id !== action.meta.databaseId
            ),
          }
          : state.databaseList;
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        databaseList: newDBList,
      };
    }
    case DATABASE_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SCHEMA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SCHEMA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SCHEMA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case SCHEMA_DELETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SCHEMA_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SCHEMA_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    // .......fILTER_Api Integration.....

    case FILTERDATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case FILTERDATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case FILTERDATA_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};
// End .......fILTER_Api Integration.....

export const createDatabase = formData => {
  return {
    types: [
      CREATE_DATABASE_REQUESTED,
      CREATE_DATABASE_SUCCESS,
      CREATE_DATABASE_FAILURE,
    ],
    promise: client =>
      client.post('createDatabase', {
        data: formData,
      }),
  };
};

export const getDatabaseList = (
  userId,
  pageNo,
  pageSize,
  searchKey = null,
  updateDBList = true
) => {
  let key = '';
  if (searchKey) {
    key = `&searchKey=${searchKey}`;
  }
  return {
    types: [
      DATABASE_LIST_REQUESTED,
      key || !updateDBList
        ? DATABASE_LIST_SUCCESS_NO_UPDATE
        : DATABASE_LIST_SUCCESS,
      DATABASE_LIST_FAILURE,
    ],
    promise: client =>
      client.get(
        `getDatabaseList?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}${key}`,
        {}
      ),
  };
};

export const deleteDatabase = (databaseId, userId) => {
  return {
    types: [
      DATABASE_DELETE_REQUESTED,
      DATABASE_DELETE_SUCCESS,
      DATABASE_DELETE_FAILURE,
    ],
    promise: client =>
      client.del(
        `deleteDatabase?databaseId=${databaseId}&userId=${userId}`,
        {}
      ),
    meta: { databaseId },
  };
};

//  new API integration
export const getSchemasList = (
  userId,
  pageNo = -1,
  pageSize = -1,
  type,
  searchKey
) => {
  let key = '';
  if (searchKey) {
    key = `&searchKey=${searchKey}`;
  }
  return {
    types: [SCHEMA_REQUESTED, SCHEMA_SUCCESS, SCHEMA_FAILURE],
    promise: client =>
      client.get(
        `getSchemasList?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}${type ? '&type=' + type : ''
        }${key}`,
        {}
      ),
  };
};

export const getSchemasDataTypes = userId => {
  return {
    types: [SCHEMA_REQUESTED, SCHEMA_SUCCESS, SCHEMA_FAILURE],
    promise: client => client.get(`getSchemasDataTypes?userId=${userId}`, {}),
  };
};

export const deleteSchema = schemaId => {
  return {
    types: [
      SCHEMA_DELETE_REQUESTED,
      SCHEMA_DELETE_SUCCESS,
      SCHEMA_DELETE_FAILURE,
    ],
    promise: client => client.del(`deleteSchema?schemaId=${schemaId}`, {}),
  };
};

// Filter Integration
export const getFilterData = (databaseId, schemaId) => {
  return {
    types: [FILTERDATA_REQUESTED, FILTERDATA_SUCCESS, FILTERDATA_FAILURE],
    promise: client =>
      client.get(
        `getFilterData?databaseId=${databaseId}&schemaId=${schemaId}`,
        {}
      ),
  };
};

export const getEmbeddedDatabaseList = apiKey => {
  return {
    types: [EMBEDDED_DATABASE_LIST, EMBEDDED_DATABASE_LIST_FAILURE],
    promise: client =>
      client.get(`getEmbeddedDatabaseList?apiKey=${apiKey}`, {}),
  };
};

export const getMetrics = userId => {
  return {
    types: [SCHEMA_REQUESTED, SCHEMA_SUCCESS, SCHEMA_FAILURE],
    promise: client => client.get(`getMetrics?userSurrogateId=${userId}`, {}),
  };
};
