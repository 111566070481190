/* eslint-disable */
import { Grid, Typography } from '@material-ui/core';
import { getDatabaseList, getFilterData } from '@reducers/database';
import { deleteItemFromFilterList, getItemDetails } from '@reducers/query';
import {
  getAddedFilterApiData,
  getApplicationTags,
  getCategoryTags,
  getChannelTags,
} from '@reducers/tags';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { change, reset } from 'redux-form';
import AddFilter from './addFilter';
import QueryComponent from './component';
import AlertDialogDelete from './deleteAlertDialog';

export class Query extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDatabaseId: '',
      prevSchemaId: '',
      currDatabaseId: '',
      currSchemaId: '',
      allFilterDataList: [],
      databaseId: '',
      schemaId: '',
      databaseList: '',
      name: '',
      databaseId: '',
      receiedDatabaseId: this.props.dbId ? this.props.dbId : '',
      // previousDatabaseId:'',
      dataApplication: '',
      dataCategory: '',
      dataChannel: '',
      dataType: [
        { id: 1, typeName: 'Image' },
        { id: 2, typeName: 'Audio' },
        { id: 3, typeName: 'Video' },
        { id: 4, typeName: 'Text' },
      ],
      objectTag: [
        { id: 1, objName: 'Title' },
        { id: 2, objName: 'Description' },
        { id: 3, objName: 'Upload Type' },
        { id: 4, objName: 'Privacy' },
      ],
      selectedApplicationTag: [],
      isDatabaseCreated: JSON.parse(
        window.localStorage.getItem('isDatabaseCreated')
      ),
      products: [],
      metadata: {},
      appIndex: 0,
      addedFilter: [],
      loading: false,
      paginationLoading: false,
      showAddFilterDialog: false,
      showAlertForDelete: false,
      hasMore: true,
      pageNo: 1,
      pageSize: 32,
      prevPayload: '',
      matchedVal: [],
      matchedKey: '',
      initialValueOfAddedFilter: {},
      error: false,
      keyInTheUrl:
        this.props.location.state && this.props.location.state.keySearch,
      onResetClick: false,
      initialValueForTitle: '',
      searchKey: '',
      onDatabaseChangeClick: false,
      userSurrogateId: window.localStorage.getItem('userSurrogateId'),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.databaseList.responseList &&
      props.databaseList.responseList !== state.databaseList &&
      !state.onDatabaseChangeClick
    ) {
      let index = 0;
      const searchParams = new URLSearchParams(props.location.search);
      const database = searchParams.get('database');
      if (database) {
        index = props.databaseList.responseList.findIndex(
          db => db.name == database
        );
        index = index == -1 ? 0 : index;
      }
      return props.databaseList.responseList &&
        props.databaseList.responseList.length
        ? {
            databaseList: props.databaseList.responseList,
            name:
              props.databaseList && props.databaseList.responseList[index].name,
            databaseId:
              props.databaseList && props.databaseList.responseList[index].id,
            schemaId:
              props.databaseList &&
              props.databaseList.responseList[index].schemaId.toString(),
          }
        : {};
    }
    if (state.keyInTheUrl) {
      return {
        name: props.dbName,
        databaseList: props.databaseList.responseList,
        databaseId: props.dbId,
      };
    }
    return null;
  }

  getSelectedDatabaseId() {
    const globalDbId = window.localStorage.getItem('globalDBId');
    if (globalDbId) {
      return globalDbId;
    }
    const firstDbId =
      this.state.databaseList && this.state.databaseList.length
        ? this.state.databaseList[0].id
        : null;
    return firstDbId;
  }

  componentDidMount() {
    const userId = this.state.userSurrogateId;
    window.scrollTo(0, 0);
    let index = 0;
    const searchParams = new URLSearchParams(this.props.location.search);
    const database = searchParams.get('database');

    if (!this.state.keyInTheUrl) {
      this.props.getDatabaseList(userId, 0, 0).then(data => {
        if (data.responseList && data.responseList.length) {
          if (database) {
            index = data.responseList.findIndex(db => db.name == database);
            index = index == -1 ? 0 : index;
          }
          /* this.getApplicationTags(data.responseList[index].name);
          this.getCategoryTags(data.responseList[index].name);
          this.getChannelTags(data.responseList[index].name); */
          // if (index) {
          //   this.handleFilteredData(
          //     data.responseList &&
          //       data.responseList[index] &&
          //       data.responseList[index].id,
          //     data.responseList &&
          //       data.responseList[index] &&
          //       data.responseList[index].schemaId
          //   );
          // }
          this.getItemDetails();
          this.setState({
            name: this.props.dbName
              ? this.props.dbName
              : data.responseList[index].name,
          }); // Added later
        }
      });
    } else {
      this.props.getDatabaseList(userId, 0, 0).then(data => {
        /* this.getApplicationTags(this.props.dbName);
        this.getCategoryTags(this.props.dbName);
        this.getChannelTags(this.props.dbName); */
        // this.handleFilteredData(
        //   data.responseList && data.responseList[0] && data.responseList[0].id,
        //   data.responseList &&
        //     data.responseList[0] &&
        //     data.responseList[0].schemaId
        // );
        this.setState(prevState => ({
          addedFilter: [...prevState.addedFilter, 'Title'],
          initialValueOfAddedFilter: { title: true },
        }));
        this.setState({
          initialValueForTitle: this.props.location.state.keySearch,
        });
        this.getItemDetailsForSearch();
        // this.setState({ name: data.responseList[0].name });  // Added later
      });
    }
    // this.setState({ name: this.props.dbNameReceived ? this.props.dbNameReceived : ''})
    window.addEventListener('scroll', this.getListOnScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const userId = this.state.userSurrogateId;

    if (this.props.location.state && this.props.location.state.keySearch) {
      if (
        this.props.searchTerm &&
        prevProps.searchTerm !== this.props.searchTerm
      ) {
        this.setState(
          prevState => ({
            keyInTheUrl: this.props.location.state.keySearch,
            name: this.props.dbName,
            addedFilter: ['Title'],
            initialValueOfAddedFilter: { title: true },
          }),
          () => {
            this.setState({
              initialValueForTitle: this.props.location.state.keySearch,
            });
            /* this.getApplicationTags(this.state.name);
            this.getCategoryTags(this.state.name);
            this.getChannelTags(this.state.name); */
            this.handleFilteredData();
            this.getItemDetailsForSearch();
          }
        );
      }
      if (
        prevState.name !== this.state.name &&
        !this.props.location.state.keySearch
      ) {
        this.props.getDatabaseList(userId, 0, 0).then(data => {
          /* this.getApplicationTags(this.state.name);
          this.getCategoryTags(this.state.name);
          this.getChannelTags(this.state.name); */
          this.getItemDetails();
          this.setState({ onResetClick: false });
        });
      }
    }
    const dbId = this.getSelectedDatabaseId();
    if (this.state.prevDatabaseId !== dbId) {
      this.setState({ allFilterDataList: [] });
      this.props.getFilterData(dbId, this.state.schemaId).then(results => {
        const filterList =
          results &&
          results.responseList.map(eachFilterData => {
            return eachFilterData;
          });
        this.setState({ allFilterDataList: filterList });
      });
      this.setState({
        prevDatabaseId: dbId,
      });
    }
  }

  getAddedFilterData = () => {
    this.props
      .getAddedFilterApiData(
        this.state.databaseId,
        this.state.keyInTheUrl
          ? this.props.schemaIdReceived
          : this.state.schemaId
      )
      .then(data => {
        data.responseList.length > 0 &&
          data.responseList.map(item => {
            const names = Object.keys(item);
            const value = names
              .map(name => ({ name }))
              .map((x, i) => {
                x.id = i + 1;
                return x;
              });
            this.setState({ objectTag: value });
          });
      });
  };

  handleFilteredData = (dbId, schemaId) => {
    this.setState({ allFilterDataList: [] });
    this.props
      .getFilterData(
        dbId ||
          (this.state.keyInTheUrl || this.props.dbId
            ? this.props.dbId
            : this.props.databaseList &&
              this.props.databaseList.responseList[0].id),
        schemaId ||
          (this.state.keyInTheUrl || this.props.schemaIdReceived
            ? this.props.schemaIdReceived
            : this.props.databaseList &&
              this.props.databaseList.responseList[0].schemaId)
      )
      .then(results => {
        const filterList =
          results &&
          results.responseList.map(eachFilterData => {
            return eachFilterData;
          });
        this.setState({ allFilterDataList: filterList }, () => {
          this.getAddedFilterData();
        });
      });
    this.setState({
      prevDatabaseId: this.state.currDatabaseId,
    });
    // this.setState({ name: this.props.dbNameReceived ? this.props.dbNameReceived : ''})
  };

  componentWillUnmount() {
    this.props.history.push({
      state: { keySearch: '' },
    });
    window.removeEventListener('scroll', this.getListOnScroll);
    window.localStorage.setItem('filterPage', true);
  }

  getListOnScroll = () => {
    if (this.state.hasMore) {
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.scrollY;
      if (windowBottom >= docHeight && this.props.loading === false) {
        this.setState(() => {
          this.getItemDetails(
            this.state.prevPayload !== ''
              ? { ...this.state.prevPayload, pageNo: this.state.pageNo }
              : null,
            'onScroll'
          );
        });
      }
    }
  };

  handleChange = (name, resetData) => event => {
    this.props.history.push({
      pathname: `/search`,
      state: { keySearch: '' },
    });
    document.getElementById('search').value = '';
    this.setState(
      {
        [name]: event.target.value,
        hasMore: true,
        pageNo: 1,
        prevPayload: '',
        onResetClick: true,
        onDatabaseChangeClick: true,
      },
      () => {
        /* this.getApplicationTags(this.state.name);
        this.getCategoryTags(this.state.name);
        this.getChannelTags(this.state.name); */
        this.getItemDetails();
      }
    );
    this.resetForm(resetData);
    this.props.databaseList.responseList &&
      this.props.databaseList.responseList.map(results => {
        if (results.name === event.target.value) {
          this.setState(
            {
              databaseId: results.id.toString(),
              schemaId: results.schemaId.toString(),
            },
            () => {
              this.getAddedFilterData();
            }
          );
        }
      });
    const userId = this.state.userSurrogateId;
    this.props.getDatabaseList(userId, 0, 0).then(data => {
      const myListId =
        data.responseList &&
        data.responseList
          .map(results => {
            if (results.name == event.target.value) {
              return results.id;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });
      const myListSchemasId =
        data.responseList &&
        data.responseList
          .map(results => {
            if (results.name == event.target.value) {
              return results.schemaId;
            }
          })
          .filter(function(element) {
            return element !== undefined;
          });
      const databaseIdString = myListId.toString();
      const schemasIdString = myListSchemasId.toString();
      this.setState({
        databaseId: databaseIdString,
        schemaId: schemasIdString,
        currDatabaseId: databaseIdString,
        currSchemaId: schemasIdString,
      });
    });
  };

  getApplicationTags = name => {
    const userId = this.state.userSurrogateId;
    this.props
      .getApplicationTags(userId, name)
      .then(data => {
        const dataApplication = data.responseList;
        this.setState(prevState => ({
          ...prevState,
          dataApplication,
        }));
      })
      .catch(err => console.log('err', err));
  };

  getCategoryTags = name => {
    const userId = this.state.userSurrogateId;
    this.props
      .getCategoryTags(userId, name)
      .then(data => {
        const dataCategory = data.responseList;
        this.setState(prevState => ({
          ...prevState,
          dataCategory,
        }));
      })
      .catch(err => console.log('err', err));
  };

  getChannelTags = name => {
    const userId = this.state.userSurrogateId;
    this.props
      .getChannelTags(userId, name)
      .then(data => {
        const dataChannel = data.responseList;
        this.setState(prevState => ({
          ...prevState,
          dataChannel,
        }));
      })
      .catch(err => console.log('err', err));
  };

  flattenObject = ob => {
    const result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur || Array.isArray(cur)) {
        // If the current value is not an object or is an array, add it directly
        result[prop] = cur;
      } else {
        // If the current value is an object, continue flattening
        for (const key in cur) {
          if (cur.hasOwnProperty(key)) {
            recurse(cur[key], prop ? `${prop}.${key}` : key);
          }
        }
      }
    }

    recurse(ob, '');
    return result;
  };

  takeObject = data => {
    const newData = {};
    Object.keys(data).forEach(key => {
      const transformedKey = key.replace(/__/g, '.');
      newData[transformedKey] = data[key];
    });
    data = newData;

    // const userId = this.state.userSurrogateId;
    // if(this.state.previousDatabaseId==this.state.databaseId.toString()){
    //     this.setState({receiedDatabaseId: this.state.previousDatabaseId})
    // }
    let payload = {
      // userId: userId,
      databaseId: this.state.databaseId.toString(),
      pageNo: 1,
      pageSize: this.state.pageSize,
    };
    // this.setState({previousDatabaseId:this.state.receiedDatabaseId})
    this.setState({
      databaseId: this.state.receiedDatabaseId
        ? this.state.receiedDatabaseId
        : this.state.databaseId.toString(),
    });
    this.setState({ receiedDatabaseId: '' });
    return new Promise((resolve, reject) => {
      try {
        let nonEmptyObj = Object.fromEntries(
          Object.entries(data).filter(([_, v]) => v != false)
        );
        const selectedData = Object.keys(nonEmptyObj);
        const objData = {};
        {
          this.state.allFilterDataList &&
            this.state.allFilterDataList.length > 0 &&
            Object.entries(this.state.allFilterDataList[0]).map(
              ([key, value]) => {
                const selectedAppData = value.filter(o1 =>
                  selectedData.some(o2 => o1?.replaceAll('.', '') === o2)
                );
                if (selectedAppData.length > 0) {
                  Object.assign(payload, { [key]: selectedAppData });
                }
              }
            );
          const selectedObjectTagData = selectedData.filter(o1 =>
            this.state.objectTag.some(o2 => o1 === o2.name)
          );
          if (selectedObjectTagData.length !== 0) {
            for (var i in data) {
              if (selectedObjectTagData.includes(i)) {
                // _.camelCase(objData[i]) = data[i];
                objData[_.camelCase(i)] = data[i];
              }
            }
            Object.assign(payload, objData);
          }
          // if (this.state.keyInTheUrl !== '') {
          //     Object.assign(payload, this.state.keyInTheUrl )
          // }
        }
        console.log('payload', payload);
        payload = this.flattenObject(payload);
        console.log('payload', payload);
        return resolve(payload);
        // const copyData = {};
        // const objData = {};
        // const toBeFiltered = selectedData.filter(el => {
        //     return !this.state.addedFilter.find(element => {
        //         return element === el;
        //     });
        // });
        // const selectedAppData = selectedData.filter(o1 => this.state.dataApplication.some(o2 => o1 === o2.tagName));
        // const selectedCategoryData = selectedData.filter(o1 => this.state.dataCategory.some(o2 => o1 === o2.categoryName));
        // const selectedChannelData = selectedData.filter(o1 => this.state.dataChannel.some(o2 => o1 === o2.channelName));
        // const selectedTypeData = selectedData.filter(o1 => this.state.dataType.some(o2 => o1 === o2.typeName));
        // const selectedObjectTagData = selectedData.filter(o1 => this.state.objectTag.some(o2 => o1 === o2.objName));
        // if (selectedCategoryData.length !== 0) {
        // Object.assign(payload, { category: ['Jacket'] })
        // }
        // if (selectedChannelData.length !== 0) {
        //     Object.assign(payload, { channel: selectedChannelData })
        // }
        // if (selectedTypeData.length !== 0) {
        //     Object.assign(payload, { type: selectedTypeData })
        // }
        // if (selectedAppData.length !== 0) {
        //     for (var i in data) {
        //         if (selectedAppData.includes(i)) {
        //             copyData[i] = data[i];
        //         }
        //     }
        //     Object.assign(payload, { applicationTags: copyData })
        // }
        // if (selectedObjectTagData.length !== 0) {
        //     for (var i in data) {
        //         if (selectedObjectTagData.includes(i)) {
        //             // _.camelCase(objData[i]) = data[i];
        //             objData[_.camelCase(i)] = data[i];
        //         }
        //     }
        //     Object.assign(payload, { objectTags: objData })
        // }
        // return resolve(payload)
      } catch (error) {
        return resolve('failure');
      }
    });
  };

  handleSubmit = data => {
    this.setState({ products: [], pageNo: 1 }, () => {
      this.takeObject(data).then(payload => {
        window.scrollTo(0, 0);
        this.getItemDetails(payload);
        this.setState({
          prevPayload: payload,
        });
      });
    });
  };

  getItemDetailsForSearch = () => {
    const userId = this.state.userSurrogateId;
    this.setState({ products: [] }, () => {
      const payload = {
        // userId: userId,
        databaseId: this.state.keyInTheUrl
          ? this.props.dbId
          : this.state.databaseId.toString(),
        title: this.state.keyInTheUrl,
        pageNo: 1,
        pageSize: this.state.pageSize,
      };
      window.scrollTo(0, 0);
      this.getItemDetails(payload);
      this.setState({
        prevPayload: payload,
      });
    });
  };

  getItemDetails = (payload, calledFrom) => {
    if (this.state.loading || this.state.paginationLoading) {
      return;
    }
    if (calledFrom === 'onScroll') {
      this.setState(() => ({
        paginationLoading: true,
      }));
    }
    const userId = this.state.userSurrogateId;
    this.setState({ loading: true });
    const callPayload = payload
      ? payload
      : {
          databaseId:
            this.state.keyInTheUrl || this.props.dbId
              ? this.props.dbId
              : this.state.databaseId.toString(),
          pageNo: this.state.pageNo,
          pageSize: this.state.pageSize,
        };
    this.props
      .getItemDetails(callPayload)
      .then(data => {
        if (
          calledFrom === 'onScroll' ||
          (callPayload.pageNo || this.state.pageNo) != 1
        ) {
          this.setState(prevState => ({
            ...prevState,
            products: (prevState.products || []).concat(data.items || []),
            metadata: data.metadata ? data.metadata : prevState.metadata,
            pageNo: callPayload.pageNo + 1,
            hasMore: data.items && data.items.length > 0 ? true : false,
            error: false,
          }));
        } else {
          this.setState(prevState => ({
            ...prevState,
            products: data.items || [],
            metadata: data.metadata || {},
            pageNo: (callPayload.pageNo || this.state.pageNo) + 1,
            hasMore: data.items && data.items.length > 0 ? true : false,
            error: false,
          }));
        }
        /* if (this.state.products.length > 0 && this.state.pageNo > 0) {
          // console.log('pageNo after getItemDetails', this.state.pageNo);
          this.setState(prevState => ({
            ...prevState,
            products: data.items
              ? calledFrom === 'onScroll'
                ? prevState.products.concat(data.items)
                : data.items
              : prevState.products,
            metadata: data.metadata ? data.metadata : prevState.metadata,
            pageNo: callPayload.pageNo + 1,
            hasMore: data.items && data.items.length > 0 ? true : false,
            loading: false,
            paginationLoading: false,
            error: false,
          }));
        } else {
          // console.log('pageNo after getItemDetails else', this.state.pageNo);
          this.setState(prevState => ({
            ...prevState,
            products: data.items || [],
            metadata: data.metadata || {},
            pageNo: callPayload.pageNo + 1,
            hasMore: data.items && data.items.length > 0 ? true : false,
            loading: false,
            paginationLoading: false,
            error: false,
          }));
        } */
      })
      .catch(err => {
        console.log('error', err);
        this.setState({
          error: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          paginationLoading: false,
        });
      });
  };

  onChangeApplicationTag = (e, data, isApplicationTag) => {
    // if (isApplicationTag) {
    //     if (data) {
    //         this.setState({selectedApplicationTag: this.state.selectedApplicationTag.concat([e])})
    //     } else {
    //         this.setState({selectedApplicationTag: this.state.selectedApplicationTag.filter(function(el) {
    //             return el !== e
    //         })});
    //     }
    // }
    if (isApplicationTag) {
      const dataApplication = this.state.dataApplication.map(obj => ({
        ...obj,
        isChecked: data,
      }));
      this.setState(prevState => ({
        ...prevState,
        dataApplication,
      }));
    }
  };

  addRemoveFilter = type => {
    if (type === 'add') {
      this.setState({
        addedFilter: this.state.addedFilter.concat(
          this.state.dataApplication[this.state.appIndex]
        ),
        appIndex: this.state.appIndex + 1,
      });
    }
  };

  resetForm = (reset, click) => {
    if (click === 'reset') {
      this.props.history.push({
        pathname: `/search`,
        state: { keySearch: '' },
      });
      document.getElementById('search').value = '';
    }
    reset();
    reset('addFilterForm');
    this.setState(
      {
        addedFilter: [],
        appIndex: 0,
        keyInTheUrl: '',
        onResetClick: true,
        initialValueOfAddedFilter: {},
        initialValueForTitle: '',
      },
      () => {
        this.handleSubmit({});
      }
    );
  };

  goToSaveAddedFilter = (data, dispatch) => {
    const newData = {};
    Object.keys(data).forEach(key => {
      const transformedKey = key.replace(/__/g, '.');
      newData[transformedKey] = data[key];
    });
    data = newData;
    let nonEmptyObj = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != false)
    );
    let removedObj = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v == false)
    );
    const selectedData = Object.keys(nonEmptyObj);
    const removedData = Object.keys(removedObj);
    for (var i = 0; i < removedData.length; i++) {
      this.props.dispatch(change('queryForm', removedData[i], false));
      // this.props.dispatch(untouch('queryForm',keyData[i]))
    }
    this.setState(
      {
        addedFilter: selectedData,
        showAddFilterDialog: !this.state.showAddFilterDialog,
        initialValueOfAddedFilter: data,
      },
      () => {
        dispatch(reset('addFilterForm'));
      }
    );
    // var objDiv = document.getElementsByClassName("makeStyles-drawerPaper-22");
    //     objDiv.scrollTop = objDiv.scrollHeight;
  };

  onDeleteItem = () => {
    const userId = this.state.userSurrogateId;
    const payload = {
      userId: userId,
      name: this.state.name,
      objectId: this.state.itemToDelete,
    };
    this.props.deleteItemFromFilterList(payload).then(() => {
      if (payload.objectId !== 'all') {
        this.setState(prevState => ({
          showAlertForDelete: !this.state.showAlertForDelete,
          products: prevState.products.filter(
            product => product.id !== payload.objectId
          ),
        }));
      } else {
        this.setState({
          showAlertForDelete: !this.state.showAlertForDelete,
          products: [],
        });
      }
    });
  };

  showAlertForDelete = id => {
    this.setState({
      itemToDelete: id,
      showAlertForDelete: !this.state.showAlertForDelete,
    });
  };

  onClickSimilar = HashValue => {
    // const userId = this.state.userSurrogateId;
    const payload = {
      //    userId: userId,
      databaseId: this.state.databaseId,
      dHash: HashValue,
      pageNo: 1,
      pageSize: this.state.pageSize,
    };
    this.setState({ products: [], pageNo: 1 }, () => {
      window.scrollTo(0, 0);
      this.getItemDetails(payload);
      this.setState({
        prevPayload: payload,
      });
    });
  };

  render() {
    if (!(this.state.databaseList && this.state.databaseList.length)) {
      return (
        <Grid container direction="row" className="p-3r">
          <Grid item xs={12} className="my-10">
            <Typography variant="subtitle">
              <b>No Database found. Create a Database to use Filter.</b>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className="">
        <QueryComponent
          allFilterDataList={this.state.allFilterDataList}
          databaseList={this.state.databaseList}
          databaseValue={this.state.name}
          handleChange={this.handleChange}
          onSubmit={this.handleSubmit}
          dataApplication={this.state.dataApplication}
          dataCategory={this.state.dataCategory}
          dataChannel={this.state.dataChannel}
          dataType={this.state.dataType}
          selectedApplicationTag={this.state.selectedApplicationTag}
          products={this.state.products}
          metadata={this.state.metadata}
          addRemoveFilter={this.addRemoveFilter}
          addedFilter={this.state.addedFilter}
          appIndex={this.state.appIndex}
          resetForm={this.resetForm}
          loading={this.state.loading}
          setOpen={() =>
            this.setState({
              showAddFilterDialog: !this.state.showAddFilterDialog,
            })
          }
          showAddFilterDialog={this.state.showAddFilterDialog}
          paginationLoading={this.state.paginationLoading}
          onDeleteItem={this.onDeleteItem}
          showAlertForDelete={this.showAlertForDelete}
          onClickSimilar={this.onClickSimilar}
          error={this.state.error}
          keyInTheUrl={this.state.keyInTheUrl}
          initialValue={this.state.initialValueForTitle}
          initialValues={{ Title: this.state.initialValueForTitle }}
        />
        <AddFilter
          dataApplication={this.state.dataApplication}
          showAddFilterDialog={this.state.showAddFilterDialog}
          setOpen={() =>
            this.setState({
              showAddFilterDialog: !this.state.showAddFilterDialog,
            })
          }
          onSubmit={this.goToSaveAddedFilter}
          objectTag={this.state.objectTag}
          databaseValue={this.state.name}
          resetForm={this.resetForm}
          initialValues={this.state.initialValueOfAddedFilter}
        />
        <AlertDialogDelete
          showAlertForDelete={this.state.showAlertForDelete}
          setOpen={() =>
            this.setState({
              showAlertForDelete: !this.state.showAlertForDelete,
            })
          }
          onDeleteItem={this.onDeleteItem}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  databaseList: state.database.databaseList,
  loading: state.query.loading,
  dbName: state.search.dbName,
  searchTerm: state.search.searchKey,
  dbId: state.search.sentDbId,
  schemaIdReceived: state.search.sentSchemaId,
  // dbNameReceived:state.search.sentDatabaseName,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDatabaseList,
      getFilterData,
      getApplicationTags,
      getCategoryTags,
      getChannelTags,
      getItemDetails,
      getAddedFilterApiData,
      deleteItemFromFilterList,
      dispatch,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Query));
