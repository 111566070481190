/* eslint-disable */
import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import styles from './index.module.scss';
import api from '../../constants/api.js';
import CopyToClipboardButton from '../CopyToClipboardButton/index.jsx';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { usePostHog } from 'posthog-js/react';
import logMessages from '../../constants/logMessages';

const MlModels = () => {
  const posthog = usePostHog();
  const [mlModelsList, setMlModelsList] = useState([]);
  const [isLoadingModels, setIsLoadingModels] = useState(true);
  const [expandedCardModelIds, setExpandedCardModelIds] = useState({});

  useEffect(() => {
    console.log('api.mlBackendHostName: ' + api.mlBackendHostName);
    const fetchMlModels = async () => {
      const url = `${api.mlBackendHostName}/${api.endpoints.mlModels}`;
      try {
        setIsLoadingModels(true);
        const response = await fetch(url);
        let models = await response.json();
        models = models.filter(model => !!model.host);
        setMlModelsList(models);
      } catch (err) {
        console.error('err', err);
        setMlModelsList(null);
      } finally {
        setIsLoadingModels(false);
      }
    };
    fetchMlModels();
  }, []);

  return (
    <div className={styles['models-page']}>
      <h4 className={styles['page-title']} style={{ fontSize: '21px' }}>
        ML Models
      </h4>
      {mlModelsList?.length ? (
        <div className={styles['models-page__card-list']}>
          {mlModelsList.map((model, index) => (
            <div
              className={[
                styles['card-item'],
                model.usageData ? styles['card-item__clickable'] : '',
                expandedCardModelIds[index]
                  ? styles['card-item__expanded']
                  : '',
              ].join(' ')}
              onClick={() => {
                if (!model.usageData) {
                  return;
                }
                const newExpandedCardModelIds = { ...expandedCardModelIds };
                newExpandedCardModelIds[index] = !newExpandedCardModelIds[
                  index
                ];
                setExpandedCardModelIds(newExpandedCardModelIds);
              }}
            >
              <div className={styles['card-item__title']}>{model.name}</div>
              <div className={styles['card-item__sub-title']}>
                {model.description}
              </div>
              <div
                className={styles['card-item__url-container']}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <div className={styles['text-wrapper']}>
                  <div className={styles['text-wrapper__text']}>
                    {model.url}
                  </div>
                </div>
                <div
                  onClick={e => {
                    posthog?.capture(
                      logMessages.COPIED_ML_MODEL_URL_TO_CLIPBOARD,
                      {
                        URL: model.url,
                      }
                    );
                  }}
                >
                  <CopyToClipboardButton text={model.url} />
                </div>
              </div>
              <div
                className={[
                  styles['card-item__attributes'],
                  styles['card-item__attributes--align-end'],
                ].join(' ')}
              >
                <span className={styles['attribute']}>{model.modelName}</span>
                <span className={styles['attribute__delimiter']}>|</span>
                <span
                  className={[
                    styles['attribute'],
                    // model.isBuiltIn ? styles['attribute--green'] : '',
                  ].join(' ')}
                >
                  {model.isBuiltIn ? '' : 'Hosted on: '}
                  {model.host}
                </span>
              </div>
              {model.usageData && (
                <div
                  className={[
                    styles['model-usage'],
                    expandedCardModelIds[index]
                      ? styles['model-usage__expanded']
                      : '',
                  ].join(' ')}
                >
                  <div
                    className={[
                      styles['model-usage__header'],
                      expandedCardModelIds[index]
                        ? styles['model-usage__header--visible']
                        : '',
                    ].join(' ')}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <div className={styles['title']}>
                      {model.usageData.title}
                    </div>
                    <div
                      className={styles['copy-btn']}
                      onClick={e => {
                        posthog?.capture(
                          logMessages.COPIED_ML_MODEL_CONTENT_TO_CLIPBOARD,
                          { content: model.usageData.content }
                        );
                      }}
                    >
                      <CopyToClipboardButton text={model.usageData.content} />
                    </div>
                  </div>
                  {expandedCardModelIds[index] && (
                    <SyntaxHighlighter
                      language="python"
                      style={docco}
                      className={[styles['model-usage__textarea']].join(' ')}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      {expandedCardModelIds[index]
                        ? model.usageData.content
                        : ''}
                    </SyntaxHighlighter>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : isLoadingModels ? (
        <div className={styles['page-info']}>Loading...</div>
      ) : (
        <div className={styles['page-info']}>No Models Available</div>
      )}
    </div>
  );
};

export default MlModels;
