/* eslint-disable */
import Bin from '@assets/icons/bin.svg';
import { ReactComponent as UPLOAD_FILE } from '@assets/icons/uploadFile.svg';
import NotificationMessage from '@components/NotificationMessage';
import { CUSTOM_MESSAGES, VALUES } from '@constants';
import { TextField, TextareaAutosize } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { reduxForm } from 'redux-form';
import ProgressBar from '../../components/common/formElements/ProgressBar';
import EN from '../../localization/EN';
import { useAppState } from '../AppProvider/AppProvider';
import style from './PopulateData.module.scss';
import { APIConfig } from '@constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      top: 20,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PopulateData = props => {
  const uploadFile = useRef();
  const [primaryValue, setValue] = useState([
    props.availableDatabaseList &&
      props.availableDatabaseList.length &&
      props.availableDatabaseList[0].name,
  ]);
  const [state, dispatch] = useAppState();
  const [dbName, setDbName] = useState(state.globalDatabaseName || '');
  const [databaseId, setDatabaseId] = useState();
  const [indexCreated, setIndexCreated] = useState(false);
  const theme = useTheme();
  const [uploadType, setUploadType] = useState();
  const [urls, setUrls] = useState();
  const [jsonPath, setJsonPath] = useState(null);

  useEffect(() => {
    setDbName(state.globalDatabaseName);
  }, [state.globalDatabaseName]);

  useEffect(() => {
    let dbId = null;
    let isSchemaContentType = false;
    let indexCreated = false;
    if (
      dbName &&
      props.availableDatabaseList &&
      props.availableDatabaseList.length
    ) {
      const dbObj = props.availableDatabaseList.filter(
        obj => obj.name === dbName
      )[0];
      dbId = dbObj ? dbObj.id : props.availableDatabaseList[0].id;
      isSchemaContentType = dbObj
        ? dbObj.contentType
        : props.availableDatabaseList[0].contentType;
      indexCreated = dbObj.indexCreated;
    }
    setDatabaseId(dbId);
    // setIndexCreated(indexCreated);
    setIndexCreated(true);

    props.handleDatabaseId(dbId);
    // setSchemaContentType(isSchemaContentType);
    setUploadType(VALUES.UPLOAD_TYPES && VALUES.UPLOAD_TYPES[0]);
  }, [dbName, props.availableDatabaseList]);

  useEffect(() => {
    let eventSource;
    let dbId;
    if (
      dbName &&
      props.availableDatabaseList &&
      props.availableDatabaseList.length
    ) {
      const dbObj = props.availableDatabaseList.filter(
        obj => obj.name === dbName
      )[0];
      dbId = dbObj ? dbObj.id : props.availableDatabaseList[0].id;
    }
    if (!indexCreated && dbId) {
      eventSource = new EventSource(
        `${APIConfig.hostname}/profile/database/${dbId}/status`
      );

      eventSource.onopen = () => {
        console.log('SSE connection established');
      };

      eventSource.addEventListener(dbId, event => {
        console.log('Received message:', event.data);
        if (event.data === 'COMPLETE') {
          setIndexCreated(true);
        }
      });

      eventSource.onerror = error => {
        console.error('SSE error:', error);
        eventSource.close();
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [dbName, indexCreated, props.availableDatabaseList]);

  useEffect(() => {
    setUrls(null);
    handleRemoveFiles();
  }, [uploadType, databaseId]);

  useEffect(() => {
    if (
      dbName &&
      props.availableDatabaseList &&
      props.availableDatabaseList.length
    ) {
      const id = props.availableDatabaseList[0].id;
      const isSchemaContentType =
        props.availableDatabaseList[0].contentType || false;
      // setSchemaContentType(isSchemaContentType);
    }
  }, [props.availableDatabaseList]);

  const handleChange = event => {
    const {
      target: { value },
    } = event;

    setValue(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleRemoveFiles = () => {
    resetFileInput();
    props.clearAllFiles();
  };

  const resetFileInput = () => {
    if (uploadFile && uploadFile.current && uploadFile.current.value) {
      uploadFile.current.value = '';
    }
  };

  const handleSubmit = data => {
    data.preventDefault();
    props.handleSubmit(data, uploadType, urls, jsonPath);
    resetFileInput();
  };

  const renderForm = () => {
    const userId =
      window.localStorage.getItem('isDatabaseCreated') &&
      JSON.parse(window.localStorage.getItem('isDatabaseCreated'))[1].userId;
    const databaseList =
      props.availableDatabaseList &&
      props.availableDatabaseList.map(Database => {
        /* if (Database.schemaId == ) {
        setSchemaIdToShowPdfInUploadOptions(Database.schemaId);
      } */
        return Database.name;
      });

    const renderSelectField = ({
      input,
      label,
      meta: { touched, error },
      children,
      ...custom
    }) => (
      <Select
        id={label}
        name={label}
        displayEmpty
        value={primaryValue}
        onChange={handleChange}
        variant="outlined"
        {...input}
        renderValue={selected => {
          if (selected.length === 0) {
            if (label === 'name') {
              setDbName(children[1][0].key);
              window.localStorage.setItem('selectedDB', children[1][0].key);
            }
            return <Typography>{children[1][0].key}</Typography>;
          }
          if (label === 'name') {
            setDbName(selected);
            window.localStorage.setItem('selectedDB', selected);
            props.handleDatabaseId(databaseId);
          }
          return selected;
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        children={children}
        {...custom}
      ></Select>
    );

    const handleUrlsChange = urlsString => {
      if (!urlsString) {
        setUrls(null);
        return;
      }
      let urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

      let urls = urlsString.match(urlRegex);
      setUrls(urls);
    };

    const handleFileUploads = e => {
      props.handleFileUploads(e);
      resetFileInput();
    };

    const handleJsonPathChange = jsonPath => {
      if (!jsonPath) {
        setJsonPath(null);
        return;
      }
      setJsonPath(jsonPath);
    };

    return (
      <div className={style.formContainer}>
        <form onSubmit={handleSubmit} className="w-100">
          <Grid container>
            <>
              <Grid item xs={12} className="my-10">
                <Typography variant="subtitle">
                  <b>{EN.SELECT_UPLOAD_TYPE}</b>
                </Typography>
                <Box>
                  <FormControl className="w-100">
                    <Select
                      id={'uploadType'}
                      name={'uploadType'}
                      displayEmpty
                      value={uploadType || VALUES.UPLOAD_TYPES[0]}
                      defaultValue={uploadType || VALUES.UPLOAD_TYPES[0]}
                      onChange={e => setUploadType(e.target.value)}
                      variant="outlined"
                      renderValue={selected => {
                        /* if (!showPDFOptions) {
                          return VALUES.UPLOAD_TYPES && VALUES.UPLOAD_TYPES[0];
                        } */
                        return selected;
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem disabled value="">
                        <em>{EN.SELECT_UPLOAD_TYPE}</em>
                      </MenuItem>
                      {VALUES.UPLOAD_TYPES.map(item => (
                        <MenuItem
                          key={item}
                          value={item}
                          style={getStyles(item, primaryValue, theme)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {(uploadType == 'PDF' || uploadType == 'URLs') && (
                <TextField
                  variant="outlined"
                  aria-label="JSON path"
                  placeholder="JSON path (Optional)"
                  style={{
                    width: '100%',
                  }}
                  inputProps={{
                    style: {
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    },
                  }}
                  onChange={e => handleJsonPathChange(e.target.value)}
                  defaultValue=""
                />
              )}
              {uploadType == 'URLs' ? (
                <Grid item xs={12} className="my-10">
                  <TextareaAutosize
                    rows={4}
                    minRows={4}
                    maxRows={10}
                    aria-label="maximum height"
                    placeholder="Enter URLs"
                    style={{
                      width: '100%',
                      maxHeight: '247px',
                      overflow: 'auto',
                    }}
                    onChange={e => handleUrlsChange(e.target.value)}
                    defaultValue=""
                  />
                </Grid>
              ) : (
                <Grid item xs={12} className="my-10">
                  {uploadType == 'Excel' &&
                    (indexCreated ? (
                      <a
                        className="floatRight"
                        href={`${props.hostname}/berrydb/populate/template?databaseId=${databaseId}`}
                      >
                        {EN.DOWNLOAD_TEMPLATE}
                      </a>
                    ) : (
                      <div className={style.disabledLink}>
                        {EN.DOWNLOAD_TEMPLATE}
                      </div>
                    ))}
                  <label className={style.custom_file_upload}>
                    <input
                      ref={uploadFile}
                      type="file"
                      name="fileupload"
                      multiple
                      onChange={handleFileUploads}
                      disabled={!indexCreated}
                      accept={
                        uploadType == 'PDF'
                          ? 'application/pdf'
                          : '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                      }
                    />
                    {props.fileList && props.fileList.length ? (
                      <div className={style['file-list']}>
                        {props.fileList.map((file, index) => (
                          <div className={style['file-item']}>
                            <Typography
                              className={[
                                'align-self-center',
                                style['text-ellipsis'],
                              ].join(' ')}
                            >
                              {file.name}
                            </Typography>
                            <Typography
                              onClick={e => {
                                e.preventDefault();
                                props.removeFileByIndex(index);
                              }}
                              className={[
                                'align-self-center ml-8',
                                style.file_upload_inner_Button,
                              ]}
                            >
                              <img className="my-11" src={Bin} alt="" />
                            </Typography>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <Typography
                          className={[
                            'm-auto placeholderClr fw-500',
                            style['no-file-info'],
                          ].join(' ')}
                        >
                          <UPLOAD_FILE />
                          <span>{EN.SELECT_FILE}</span>
                        </Typography>
                      </>
                    )}
                  </label>
                  <div className={style.note}>{props.invalidFileUpload}</div>
                </Grid>
              )}
              <Grid item xs={12} className="my-10 textEnd">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={style.customBtn}
                  disabled={
                    uploadType === 'URLs'
                      ? !(indexCreated && urls && urls.length)
                      : !(
                          indexCreated &&
                          props.fileList &&
                          props.fileList.length
                        )
                  }
                >
                  {uploadType === 'URLs' ? 'SUBMIT' : EN.UPLOAD}
                </Button>
              </Grid>
            </>
          </Grid>
        </form>
      </div>
    );
  };

  if (!(props.availableDatabaseList && props.availableDatabaseList.length)) {
    return (
      <Grid container direction="row" className="p-3r">
        <Grid item xs={12} className="my-10">
          <Typography variant="subtitle">
            <b>No Database found. Create a Database to populate.</b>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" className="p-3r">
      <Grid item xs={12}>
        <Typography variant="h5">
          <Link to="/populate-pdf" className={style['no-link-style']}>
            <b>{EN.POPULATE}</b>
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle" className="p-3r">
          <p>{CUSTOM_MESSAGES.POPULATE_DESC}</p>
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4} className="d-flex justify-content-center">
          {props.isLoading ? (
            <CircularProgress
              style={{ position: 'absolute', top: '40%', right: '45%' }}
            />
          ) : (
            renderForm()
          )}
          <div>
            <NotificationMessage
              open={props.open}
              msg={props.successMsg ? props.successMsg : props.failureMsg}
              msgType={props.successMsg ? 'success' : 'error'}
              handleClose={props.handleClose}
            />
          </div>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={props.isUploadInProgress}
      >
        <ProgressBar progress={props.progress} />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={!indexCreated}
        style={{ position: 'absolute' }}
      >
        <div className={style.indexStatusMessage}>
          Index is being created, please wait...
        </div>
      </Backdrop>
    </Grid>
  );
};

export default reduxForm({
  form: 'populateForm', // a unique identifier for this form
})(PopulateData);
