/* eslint-disable */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Path, EditType } from '../types';
import { Chip, withStyles } from '@material-ui/core';
import InsightsIcon from '@mui/icons-material/Insights';
import { dataSlice } from '../features/data/dataSlice';
import { isNumber, isString } from '../utils/is';

const StyleChip = withStyles({
  root: {
    backgroundColor: '#026c46',
  },
})(Chip);

export const EditButtons = ({ data, path, pipelineFields, hidden = false }) => {
  const filter = useSelector(state => state.data.filter);
  const ftsfilter = useSelector(state => state.data.ftsfilter);
  const displayTextFieldPath = useSelector(
    state => state.data.displayTextField
  );
  const displayImageFieldPath = useSelector(
    state => state.data.displayImageField
  );

  const onCopyButtonClicked = useCallback(event => {
    if (navigator.clipboard) {
      const value = event.currentTarget.getAttribute('data-value') || '';
      navigator.clipboard.writeText(`${value}`);
    }
  }, []);

  const dispatch = useDispatch();
  const { deletePath, setEditMode, setUpdateFilters } = dataSlice.actions;

  const onDeleteButtonClicked = useCallback(() => {
    dispatch(deletePath(path));
    dispatch(setUpdateFilters(path));
  }, [path, dispatch, deletePath]);

  const onEditButtonClicked = useCallback(() => {
    // alert(
    //   'The Key of the edited value : ' + path.toString().replaceAll(',', ' -> ')
    // );
    dispatch(setEditMode({ path, type: 1 }));
  }, [dispatch, path, setEditMode]);

  const sanitizedPath = path.join('.').replace(/.0/g, '[0]');

  return (
    <>
      {filter && filter.includes(path.join('.')) && (
        <button className="btn btn-sm btn-link ml-1">
          {/* <i className="fas fa-filter" aria-hidden="true" /> */}
          <StyleChip label="Filter" color="primary" size="small" />
        </button>
      )}
      {ftsfilter && ftsfilter.includes(path.join('.')) && (
        <button className="btn btn-sm btn-link ml-1">
          {/* <i className="fas fa-info-circle" aria-hidden="true" /> */}
          <StyleChip label="FTS" color="primary" size="small" />
        </button>
      )}
      {displayTextFieldPath && displayTextFieldPath === sanitizedPath && (
        <button className="btn btn-sm btn-link ml-1">
          {/* <TitleIcon style={{ fontSize: '24px' }} /> */}
          <StyleChip label="Display Text" color="primary" size="small" />
        </button>
      )}
      {displayImageFieldPath && displayImageFieldPath === sanitizedPath && (
        <button className="btn btn-sm btn-link ml-1">
          {/* <ImageIcon style={{ fontSize: '24px' }} /> */}
          <StyleChip label="Display Image" color="primary" size="small" />
        </button>
      )}
      {pipelineFields && pipelineFields[sanitizedPath] && (
        <button className="btn btn-sm btn-link ml-1">
          {/* <InsightsIcon style={{ fontSize: '24px' }} /> */}
          <StyleChip label="Enriched" color="primary" size="small" />
        </button>
      )}
      <div
        className={`edit-buttons d-flex flex-row align-items-start ${
          hidden ? 'hidden' : ''
        }`}
      >
        <button
          title="Edit the value"
          data-value={data}
          className="btn btn-sm btn-link ml-1 edit-button"
          onClick={onEditButtonClicked}
        >
          <i className="far fa-edit" />
        </button>
        {navigator.clipboard && (isString(data) || isNumber(data)) && (
          <button
            title="Copy text"
            data-value={data}
            className="btn btn-sm btn-link ml-1 copy-button"
            onClick={onCopyButtonClicked}
          >
            <i className="far fa-copy" />
          </button>
        )}
        <button
          className="btn btn-sm btn-link ml-1 delete-button"
          onClick={onDeleteButtonClicked}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
    </>
  );
};
