/* eslint-disable */
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { APIConfig, VALUES } from '@constants';
import styles from './Database.module.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import cn from 'classnames';
import camera from '@assets/icons/c1.png';
import EN from '../../localization/EN';
import user_icon from '@assets/images/default-image.png';
import { styled } from '@material-ui/core/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Pagination from '@mui/material/Pagination';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import { setDbId, setSchemaId, setDbName } from '../../reducers/search';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { getEmbeddedDatabaseList } from '@reducers/database';
import { debounce } from '@material-ui/core';
import { Snackbar } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 6,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// function getStyles(name, schemaObjectName, theme) {
//   return {
//     fontWeight:
//       schemaObjectName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

class Database extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentDbId: '',
      schemaObjectName: [],
      profile_photo: user_icon,
      apiKey: window.localStorage.getItem('apiKey'),
      embeddedDatabases: {},
      autoCompleteDatabases: null,
      isSearchFocused: false,
      fetchingAutoCompleteDbs: false,
      searchKeyValid: false,
      snackbarMessage: '',
      snackbarOpen: false,
    };
  }

  handleChange = event => {
    this.props.setSchemasId(event);
    const {
      target: { value },
    } = event;
    this.setState(
      // On autofill we get a stringified value.
      { schemaObjectName: typeof value === 'string' ? value.split(',') : value }
    );
  };
  handleCreateDatabase = () => {
    // this.setState({ createNewDatabase: true })
    this.props.history.push('/Database/Create');
    // console.log("myList1",this.props.schemasList)
  };

  componentDidMount() {
    const apiKey = window.localStorage.getItem('apiKey');
    this.props.getEmbeddedDatabaseList(apiKey).then(data => {
      if (!(data && data.responseList && data.responseList.length)) {
        return;
      }
      const embeddedDatabases = {};
      data.responseList.forEach(db => {
        embeddedDatabases[db.id] = {
          lastEmbeddedOn: db.embeddedOn ? ~~(db.embeddedOn / 1000000000) : null,
        };
      });
      this.setState({ embeddedDatabases: embeddedDatabases });
    });
  }
  renderProfile = () => {
    return (
      <div className={styles.img_normal}>
        <div className={styles.profile_image}>
          <img
            src={
              !this.props.videoThumbnail
                ? this.state.profile_photo
                : URL.createObjectURL(this.props.videoThumbnail)
            }
            className={styles.profile_image}
            alt=""
          />
        </div>
        <div
          data-toggle="dropdown"
          className={cn(styles.top_right, 'px-8 cursor')}
          onClick={() => {
            this.props.handleDropdownToggle(!this.props.dropdownToggle);
          }}
        >
          <img className={styles.cameraStyle} src={camera} alt="camera" />
          <div
            className={cn(
              this.props.dropdownToggle
                ? styles.show_dropdown
                : styles.hide_dropdown,
              'pt-0 dropdown-menu dropdown-menu-right'
            )}
          >
            <label
              htmlFor="upload-photo"
              className={cn(styles.dropdown_Item, 'dropdown-item')}
            >
              Upload Photo
            </label>
            <input
              id="upload-photo"
              type="file"
              accept=".jpg, .jpeg, .png"
              className={styles.upload_photo}
              onChange={this.props.uploadThumbnail}
            />
            {this.props.videoThumbnail && (
              <div
                role="button"
                className={cn(styles.dropdown_Item, 'dropdown-item')}
                onClick={() => this.props.removeProfilePhoto()}
              >
                {EN.REMOVE_PHOTO}
              </div>
            )}
            {/* <div className={cn(styles.dropdown_background)}
          onClick={() => {
            this.props.handleDropdownToggle(!this.props.dropdownToggle);
          }
        }
        >
          </div> */}
          </div>
        </div>
      </div>
    );
  };

  renderDatabaseDesign = () => {
    const databaseInfo = VALUES.DATABASE_DETAILS;
    return (
      <>
        <div className="row">
          <label className={styles.heading}>{databaseInfo.heading}</label>
        </div>
        <div>
          <div className="row mt-30">
            <div className="col-5">
              {/* <label className={styles.subHeading}>
                {databaseInfo.subHeading}
              </label> */}
              <div className={styles.content}>
                {/* <b>{databaseInfo.subContent}</b> */}
                {databaseInfo.contentOne}
                {/* <br />
                <br />
                <p>{databaseInfo.contentTwo}</p> */}
              </div>
              <form onSubmit={this.props.handleSubmit}>
                <div className="my-10px">
                  <TextField
                    id="outlined-basic"
                    label="Database Name"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={this.props.setDatabaseName}
                  />
                </div>
                <div className="my-10px">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    onChange={this.props.setDescription}
                  />
                </div>
                <FormControl className="w-201">
                  <InputLabel
                    id="demo-select-name-label"
                    style={{ fontSize: 14, lineHeight: 0.9 }}
                  >
                    Select Schema*
                  </InputLabel>
                  <Select
                    labelId="demo-select-name-label"
                    id="demo-select-name"
                    displayEmpty
                    required
                    value={this.state.schemaObjectName}
                    onChange={this.handleChange}
                    input={<OutlinedInput label="Select Schema" />}
                    MenuProps={MenuProps}
                  >
                    {this.props.schemasList.map(name => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={{ fontSize: 14 }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>
            </div>
            <div className="col-5">
              {this.renderProfile()}
              <div>{databaseInfo.thumbnailNote}</div>
            </div>
            <div className="row justify-content-center my-10px w-72 m-top-0">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={
                  !(this.state.schemaObjectName.length > 0 && this.props.name)
                }
                className={styles.customBtn}
                onClick={this.props.createDatabase}
              >
                {`${EN.CREATE}`}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  redirectToFilter = (id, schemaId, databaseName) => {
    this.props.history.push('/search');
    this.props.setDbId(id);
    this.props.setSchemaId(schemaId);
    this.props.setDbName(databaseName);
  };

  getAutoCompleteDatabaseList = debounce(key => {
    this.setState({ fetchingAutoCompleteDbs: true });
    this.props
      .getAutoCompleteDatabaseList(key)
      .then(res => {
        const databaseList =
          res && res.responseList && res.responseList.length
            ? res.responseList
            : [];
        this.setState({ autoCompleteDatabases: databaseList });
      })
      .catch(err => {
        this.setState({ autoCompleteDatabases: null });
      })
      .finally(() => {
        this.setState({ fetchingAutoCompleteDbs: false });
      });
  }, 500);

  renderAutoComplete = () => {
    if (!this.state.searchKeyValid) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          Enter your search key (3 characters Min.)
        </div>
      );
    }
    if (this.state.fetchingAutoCompleteDbs) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          Fetching...
        </div>
      );
    }
    if (
      !(
        this.state.autoCompleteDatabases &&
        this.state.autoCompleteDatabases.length
      )
    ) {
      return (
        <div className={[styles['item'], styles['item-msg']].join(' ')}>
          No Databases found
        </div>
      );
    }
    return (
      <div>
        {this.state.autoCompleteDatabases.map(db => (
          <div
            className={styles['item']}
            onClick={() => this.props.handleAutoCompleteClick(db)}
          >
            {db.name}
          </div>
        ))}
      </div>
    );
  };

  handleDbSelect = (dbName, dbId) => {
    this.props.dispatch(setDbId(dbId));
    this.props.dispatch(setDbName(dbName));
    if (dbName) {
      this.props.history.push(`/chat?database=${dbName}`);
      return;
    }
    this.props.history.push('/chat');
  };

  renderDatabaseList = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h5">{EN.AVAILABLE_DATABASE_LIST}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="textEnd">
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={styles.customBtn}
              onClick={this.handleCreateDatabase}
            >
              {`${EN.CREATE}`}
            </Button>
          </Grid>
        </Grid>
        <div className={styles['search-input-row']}>
          <div className={styles['search-input-wrapper']}>
            <TextField
              id="auto-complete"
              label="Search"
              className={styles['search-input']}
              type="text"
              fullWidth
              margin="normal"
              onFocus={() => this.setState({ isSearchFocused: true })}
              onBlur={() =>
                setTimeout(() => this.setState({ isSearchFocused: false }), 300)
              }
              onChange={async e => {
                const key = (e.target.value || '').trim();
                if (key && key.length > 2) {
                  this.setState({
                    searchKeyValid: true,
                    fetchingAutoCompleteDbs: true,
                  });
                  this.getAutoCompleteDatabaseList(key);
                } else {
                  this.setState({
                    searchKeyValid: false,
                    autoCompleteDatabases: null,
                  });
                }
              }}
            />
            <div className={styles['search-autocomplete-wrapper']}>
              {this.state.isSearchFocused && (
                <div className={styles['search-autocomplete']}>
                  {this.renderAutoComplete()}
                </div>
              )}
            </div>
          </div>
          <div
            className={styles['reset-btn']}
            onClick={this.props.handleAutoCompleteReset}
          >
            reset
          </div>
        </div>
        <Grid item xs={12} sm={12} md={12} className={'mt-15'}>
          <Paper className="table-responsive">
            <TableContainer component={Paper} style={{ boxShadow: 3 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow className="m-14">
                    <StyledTableCell align="center">
                      <b>Image</b>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <b>Name</b>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <b>Date</b>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <b>Description</b>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <b>Details</b>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <b>Schema</b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <b>Action</b>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.databaseList.map(database => {
                    const isDbEmbedded =
                      this.state.embeddedDatabases[database.id] &&
                      this.state.embeddedDatabases[database.id].lastEmbeddedOn;
                    return (
                      <StyledTableRow key={database.id}>
                        <StyledTableCell align="center">
                          <img
                            src={
                              database.thumbnail
                                ? database.thumbnail
                                : user_icon
                            }
                            // onClick={() => this.redirectToFilter(database.id, database.schemaId, database.name)}
                            height="120"
                            className="objectFitContain"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography>
                            <b>
                              {database.name.charAt(0).toUpperCase() +
                                database.name.slice(1)}
                            </b>
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography className="grayFont">
                            {moment
                              .utc(database.createdTime)
                              .tz(moment.tz.guess(true))
                              .format('ll')}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {database.description}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {/*  <Typography>Num Collections :&nbsp;{database.numCollections}</Typography> */}
                          <Typography>
                            {'Num Objects : ' + database.numObjects}
                          </Typography>
                          {/* <Typography>Num Channels :&nbsp;{database.numChannels}</Typography>
                      <Typography>Num Categories :&nbsp;{database.numCategories}</Typography> */}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography>{database.schemaName}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className={styles['btn-wrapper']}>
                            <NavLink
                              aria-label="embed"
                              to={`/schema/${database.schemaId}/database/${database.id}/add`}
                              style={{ fontSize: '13px' }}
                            >
                              <div
                                title="Add a record to this database"
                                className={[
                                  styles['btn'],
                                  styles['btn--blue'],
                                ].join(' ')}
                              >
                                <AddCircleOutlineIcon
                                  className={styles['btn--icon']}
                                  style={{ fontSize: '18px' }}
                                />
                                Add
                              </div>
                            </NavLink>
                            {/* <NavLink
                              aria-label="embed"
                              to={
                                database.numObjects === 0
                                  ? '#'
                                  : `/chat?database=${database.name}`
                              }
                              // target="_blank" rel="noopener noreferrer"
                              style={{ fontSize: '13px' }}
                            >
                              <div
                                className={[
                                  styles['btn'],
                                  styles['btn--blue'],
                                  database.numObjects === 0
                                    ? styles['btn--disabled']
                                    : '',
                                ].join(' ')}
                                onClick={() => {
                                  if (database.numObjects === 0) {
                                    this.setSnackbarOpen(
                                      true,
                                      'This database has no records.'
                                    );
                                  }
                                }}
                              >
                                <CodeOutlinedIcon
                                  className={styles['btn--icon']}
                                  style={{ fontSize: '18px' }}
                                />
                                Embed
                              </div>
                            </NavLink> */}
                            {
                              <div
                                aria-label="chat"
                                onClick={() =>
                                  this.handleDbSelect(
                                    database.name,
                                    database.id
                                  )
                                }
                                // target="_blank" rel="noopener noreferrer"
                                style={{ fontSize: '13px' }}
                              >
                                <div
                                  className={[
                                    styles['btn'],
                                    styles['btn--blue'],
                                    /* !isDbEmbedded
                                      ? styles['btn--disabled']
                                      : '', */
                                  ].join(' ')}
                                >
                                  <ChatOutlinedIcon
                                    className={styles['btn--icon']}
                                    style={{ fontSize: '18px' }}
                                  />
                                  Chat
                                </div>
                              </div>
                            }
                            <div
                              onClick={() =>
                                this.props.toggleDeletePopup(
                                  database.id,
                                  database.name.charAt(0).toUpperCase() +
                                    database.name.slice(1)
                                )
                              }
                              aria-label="delete"
                            >
                              <div
                                className={[
                                  styles['btn'],
                                  styles['btn--red'],
                                ].join(' ')}
                              >
                                <DeleteOutlinedIcon
                                  className={styles['btn--icon']}
                                  style={{ fontSize: '18px' }}
                                />
                                Delete
                              </div>
                            </div>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid container className="justifyContentEnd my-10px">
          <Grid item>
            <Pagination
              count={this.props.pageCount}
              color="primary"
              page={this.props.pageNo}
              onChange={this.props.handleChangePage}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  setSnackbarOpen = (setToOpen = false, snackbarMessage = '') => {
    this.setState({
      snackbarMessage: snackbarMessage,
      snackbarOpen: setToOpen,
    });
  };

  render() {
    const { databaseList, loading, loaderForTable } = this.props;
    return (
      <>
        <Grid container direction="row" className="p-3r">
          {loading ? (
            <CircularProgress
              style={{ position: 'absolute', top: '40%', right: '45%' }}
            />
          ) : databaseList &&
            databaseList.length > 0 &&
            window.location.pathname != '/Database/Create' ? (
            this.renderDatabaseList()
          ) : this.props.loadingOnCreateDatabase ? (
            <CircularProgress
              style={{ position: 'absolute', top: '40%', right: '45%' }}
            />
          ) : (
            this.renderDatabaseDesign()
          )}
          {loaderForTable && <div className="loader"></div>}
        </Grid>
        <Snackbar
          message={this.state.snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => this.setSnackbarOpen(false)}
          open={this.state.snackbarOpen}
        />
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDbId,
      setSchemaId,
      setDbName,
      getEmbeddedDatabaseList,
    },
    dispatch
  );

const ModuleForm = withRouter(connect(null, mapDispatchToProps)(Database));

export default withRouter(
  reduxForm({
    form: 'DatabaseForm',
    // enableReinitialize: true
  })(ModuleForm)
);
