/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
// import Loadable from 'react-loadable';
// import PageLoader from '@components/common/PageLoader';
import { ReactComponent as LEFT_ARROW } from '@assets/icons/leftArrow.svg';
import AnnotationFilter from '@components/AnnotationFilter';
import ChatWindow from '@components/ChatWindow';
import DatabaseTags from '@components/DatabaseTags';
import Search from '@components/Filter';
import Header from '@components/Header';
import PopulateDatabase from '@components/PopulateDatabase';
import Sidebar from '@components/Sidebar/Sidebar';
import SqlEditor from '@components/SqlEditor';
import Workspace from '@components/Workspace/Workspace';
import { APP_CONFIG, VALUES } from '@constants';
import OnRouteChange from '@containers/common/OnRouteChange';
import Dashboard from '@containers/Dashboard';
import Database from '@containers/DatabaseContainer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import classNames from 'classnames';
import App from './App';
import AddDatabaseRecord from './components/AddDatabaseRecord';
import Annotation from './components/Annotation';
import AppProvider from './components/AppProvider/AppProvider';
import MlModels from './components/MlModels';
import Notepad from './components/Notepad';
import Schemas from './components/Schemas/Schemas';
import Settings from './components/Settings';
import { Signin } from './pages';
import DetailPage from './pages/DatabaseItemDetails/DetailsContainer';
import Widgets from './pages/Widgets/Widgets';
import routes from './routes';
// import { IframeParent } from './components/js';
import { Usage } from './pages';

// import ResizableComponent from './components/SqlEditor/resizable';

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        typeof window.localStorage.getItem('username') !== undefined &&
        window.localStorage.getItem('username') !== null &&
        window.localStorage.getItem('username') !== '' ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const useStyles = makeStyles(theme => ({
  panel: {
    position: 'relative',
    // overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      // minHeight: 'calc(100vh - 64px)',
      paddingTop: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      minHeight: 'calc(100vh - 56px)',
      paddingTop: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      // height: 'calc(100vh - 64px)'
    },
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
  expandedView: {
    zIndex: 99999,
    background: '#fff',
    position: 'fixed',
    bottom: 40,
    right: 10,
    transition: '0.3s',
    borderRadius: '5px',
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
    width: 321,
    overflow: 'hidden',
    overflowY: 'auto',
    height: 'calc(100vh - 20%)',
  },
  expandedViewForHeader: {
    background: '#fff',
    transition: '0.3s',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    width: 321,
    backgroundColor: '#3f51b5',
    color: '#fff',
  },
  expandedViewForBody: {
    background: '#fff',
    transition: '0.3s',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    width: 321,
  },
}));

// const LoadingComponent = ({ isLoading, isError }) => {
//   if (isLoading) {
//     return <PageLoader />;
//   }
//   if (isError) {
//     return (
//       <div>
//         Sorry, unable to load the page
//       </div>
//     );
//   }
//   return null;
// };

const PrimerRoutes = props => {
  const classes = useStyles();
  const [url, setCurrentUrl] = useState();
  const [opened, setOpened] = useState(true);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const speedDialRef = useRef(null);

  const handleClickOutside = event => {
    if (speedDialRef.current && !speedDialRef.current.contains(event.target)) {
      handleSpeedDialClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logo =
    'https://d1if8zms14qmj9.cloudfront.net/static-assets/dashboardLogo.png';

  const resizeDispatch = () => {
    if (typeof Event === 'function') {
      window.dispatchEvent(new Event('resize'));
    } else {
      const evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

  const handleSpeedDialOpen = () => setOpenSpeedDial(!openSpeedDial);

  const handleSpeedDialClose = () => setOpenSpeedDial(false);

  const renderHeaderContent = () => {
    const info = VALUES.SIDE_PANEL;
    return (
      <div className="p-1r">
        <div>
          <label>
            <b>{info.Heading}</b>
          </label>
          <br />
          {info.Content.map(el => {
            return (
              <>
                <span className="displayInline">{el.Content}</span>
                <br />
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBodyContent = () => {
    const info = VALUES.SIDE_PANEL;
    return (
      <div className="p-1r">
        <div className="panelBody">
          {info.bodyContent.map(el => {
            return (
              <>
                <Typography variant="subtitle" className="my-5px">
                  <b>
                    <a href={el.link} target="_blank">
                      {el.heading}
                    </a>
                  </b>
                </Typography>
                <Typography className="my-5px">{el.content}</Typography>
                <br />
              </>
            );
          })}
          <Typography className="my-5px">
            {info.moreInfo.text}
            <a href={info.moreInfo.link} target="_blank">
              {info.moreInfo.link}
            </a>
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <AppProvider>
      <Router history={history}>
        <OnRouteChange currentUrl={val => setCurrentUrl(val)}>
          <Route exact path={`${APP_CONFIG.BASE_URL}/`} component={Signin} />
          {url !== '/' && (
            <>
              <Header
                currentUrl={val => {
                  setCurrentUrl(val);
                }}
                logoAltText="Berry DB"
                logo={logo}
                url={url}
                toggleDrawer={handleDrawerToggle}
              />
              <div className={classNames(classes.panel, 'theme-dark')}>
                {/* {url !== '/' &&
                  <> */}
                <Sidebar
                  routes={routes.items}
                  opened={opened}
                  toggleDrawer={handleDrawerToggle}
                />
                <Switch>
                  <Workspace opened={opened}>
                    <PrivateRoute
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Dashboard`}
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Database`}
                      component={Database}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Database/Create`}
                      component={Database}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Schemas`}
                      component={Schemas}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Widgets`}
                      component={Widgets}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/annotation`}
                      component={Annotation}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Database/tags`}
                      component={DatabaseTags}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/populate`}
                      component={PopulateDatabase}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/populate-pdf`}
                      component={PopulateDatabase}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/search`}
                      component={Search}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/detail/:databaseName/:id`}
                      component={DetailPage}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/query`}
                      component={SqlEditor}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/annotation-filter`}
                      component={AnnotationFilter}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/chat`}
                      component={ChatWindow}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/notepad`}
                      component={Notepad}
                      // lazy={() => import('./components/Notepad')}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/ml-models`}
                      // lazy={lazy(() => import('@components/MlModels'))}
                      component={MlModels}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/Usage`}
                      component={Usage}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/settings`}
                      component={Settings}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/schemaDetails/:id`}
                      component={App}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/schema/:schemaId/database/:dbId/add`}
                      // path={`${APP_CONFIG.BASE_URL}/schema/database/add`}
                      component={AddDatabaseRecord}
                    />
                    <Route
                      exact
                      path={`${APP_CONFIG.BASE_URL}/schemaDetails`}
                      component={App}
                    />
                  </Workspace>
                </Switch>
                <div ref={speedDialRef}>
                  <SpeedDial
                    ariaLabel="Settings"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon icon={<LEFT_ARROW />} />}
                    // onClose={handleSpeedDialClose}
                    onClick={handleSpeedDialOpen}
                    open={openSpeedDial}
                  >
                    {openSpeedDial && (
                      <div className={classes.expandedView}>
                        <div className={classes.expandedViewForHeader}>
                          {renderHeaderContent()}
                        </div>
                        <div className={classes.expandedViewForBody}>
                          {renderBodyContent()}
                        </div>
                      </div>
                    )}
                  </SpeedDial>
                </div>
                {/* </>} */}
              </div>
            </>
          )}
        </OnRouteChange>
      </Router>
    </AppProvider>
  );
};

export default PrimerRoutes;
