const logMessages = {
  CLICKED_SIGN_UP_WITH_OTP: 'clicked_sign_up_with_otp',
  CLICKED_ON_SEND_CODE: 'clicked_on_send_code',
  CLICKED_ON_VERIFY_CODE: 'clicked_on_verify_code',
  LOGIN_WITH_OTP_SUCCESS: 'login_with_otp_success',
  LOGIN_WITH_OTP_FAILURE: 'login_with_otp_failure',
  LOGIN_WITH_SOCIAL_SUCCESS: 'login_with_social_success',
  LOGIN_WITH_SOCIAL_FAILURE: 'login_with_social_failure',
  CLICKED_ON_SIGN_OUT: 'clicked_on_sign_out',
  CLICKED_ON_HOME_PAGE: 'clicked_on_home_page',
  CLICKED_ON_SCHEMA_PAGE: 'clicked_on_schema_page',
  CLICKED_ON_DATABASE_PAGE: 'clicked_on_database_page',
  CLICKED_ON_POPULATE_PAGE: 'clicked_on_populate_page',
  CLICKED_ON_FILTER_PAGE: 'clicked_on_filter_page',
  CLICKED_ON_ANNOTATION_PAGE: 'clicked_on_annotation_page',
  CLICKED_ON_QUERY_PAGE: 'clicked_on_query_page',
  CLICKED_ON_BERRY_GPT_PAGE: 'clicked_on_berry_gpt_page',
  CLICKED_ON_API_EXAMPLES_PAGE: 'clicked_on_api_examples_page',
  CLICKED_ON_ML_MODELS_PAGE: 'clicked_on_ml_models_page',
  CLICKED_ON_USAGE_PAGE: 'clicked_on_usage_page',
  CLICKED_ON_SETTINGS_PAGE: 'clicked_on_settings_page',
  CLICKED_ON_CREATE_SCHEMA: 'clicked_on_create_schema',
  CLICKED_ON_SAVE_SCHEMA: 'clicked_on_save_schema',
  CLICKED_ON_DELETE_SCHEMA: 'clicked_on_delete_schema',
  SAVE_SCHEMA_SUCCESS: 'save_schema_success',
  SAVE_SCHEMA_FAILURE: 'save_schema_failure',
  DELETE_SCHEMA_FAILURE: 'delete_schema_failure',
  CLICKED_ON_CREATE_DATABASE: 'clicked_on_create_database',
  CLICKED_ON_SAVE_DATABASE: 'clicked_on_save_database',
  CLICKED_ON_DELETE_DATABASE: 'clicked_on_delete_database',
  CREATE_DATABASE_SUCCESS: 'create_database_success',
  CREATE_DATABASE_FAILURE: 'create_database_failure',
  CLICKED_ON_DOWNLOAD_TEMPLATE: 'clicked_on_download_template',
  CLICKED_ON_UPLOAD_IN_POPULATE: 'clicked_on_upload_in_populate',
  COPIED_API_KEY_TO_CLIPBOARD: 'copied_api_key_to_clipboard',
  COPIED_ACCESS_TOKEN_TO_CLIPBOARD: 'copied_access_token_to_clipboard',
  COPIED_ML_MODEL_URL_TO_CLIPBOARD: 'copied_ml_model_url_to_clipboard',
  COPIED_ML_MODEL_CONTENT_TO_CLIPBOARD: 'copied_ml_model_content_to_clipboard',
  CLICKED_ON_PAGE_VIEW: 'clicked_on_page_view',
};

export default logMessages;
