/* eslint-disable */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CUSTOM_MESSAGES } from '@constants';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import styles from './index.module.scss';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PythonIcon from '@assets/icons/python-logo-only.svg';
import DescriptionIcon from '@mui/icons-material/Description';
import cn from 'classnames';

const Notepad = () => {
  const notebookList = [
    {
      id: 1,
      name: 'BerryDB cookbook',
      description:
        'This is a foundational notebook that demonstrates how to get started with BerryDB API. It shows how to connect to BerryDB, access your database, read and write records.',
      link:
        'https://colab.research.google.com/drive/1ST2uoDhH0xk02yOONpxmM0Ct2Y65ODVv',
    },
    {
      id: 2,
      name: 'ShopGPT',
      description:
        'This notebook shows how to integrate a BerryDB database containing ecommerce data (Amazon fashion data). It loads the ecommerce catalog and user specified labels as embeddings into an LLM model (in this case we use OpenAI GPT). Provides a conversational UI for a shopper to search through a massive ecommerce catalog.',
      link:
        'https://colab.research.google.com/drive/1yS8tam718A6WJj7Hh9x9F6uoIKWJL6rP',
    },
    /* {
      id: 3,
      name: 'VideoJSONLoader',
      description:
        'This notebook shows how to transcribe Youtube videos stored in a BerryDB database (VideoDB). It also extracts VideoDB records as JSON objects and loads it as an embedding into an LLM model. Provides a conversational UI for users to search through video metadata, video transcription and video text description.',
      link:
        'https://colab.research.google.com/drive/16u4yt01cRgnrD1hbh21_ffe1m60DIFz6',
    }, */
    {
      id: 3,
      name: 'DoctorGpt',
      description:
        'This notebook loads the patient records from PatientDB (BerryDB database) including the labeled data as embeddings and connects it to an LLM model. Provides a conversational UI to doctors to deep search through all their patient records. It provides an ability to search through text, database fields and scanned images',
      link:
        'https://colab.research.google.com/drive/1TqI9D9Eucseep7s8Ycv8TVcSnO7EceKu',
    },
    {
      id: 4,
      name: 'PDF Ingestion',
      description: 'This notebook ingests PDF files into a Database',
      link:
        'https://colab.research.google.com/drive/1P52SrD8fum_f8vzt2IH6MQR0XCMz2C3P',
    },
    {
      id: 5,
      name: 'FHIR Patient',
      description:
        'This notebook shows an example of the FHIR databases where the patient, conditions and observations databases are joined to show various queries. The FHIR schema is a way of storing electronic health care data.',
      link:
        'https://colab.research.google.com/drive/1SssexrNnErPJ13Ignm08pbPs8BiiBUBJ',
    },
    {
      id: 6,
      name: 'MedicalNER',
      description:
        'This notebook helps in identifying entities like Problem, Treatment and Person in PatientDB (BerryDB database).',
      link:
        'https://colab.research.google.com/drive/1MzHSv0ZMNUFPieGuUtflJ-6hEqhTb1d3',
    },
    {
      id: 7,
      name: 'Contracts',
      description:
        'This notebook helps in processing PDF files, adding metadata, performing NER and topic modelling on the ingested PDF.',
      link:
        'https://colab.research.google.com/drive/1UEgJJNmzDspvuazKjP7gR5uRNLVFQBPp#scrollTo=NYA1fBzESDXo',
    },
    {
      id: 8,
      name: 'Embed and Chat',
      description:
        'This notebook demonstrates how to embed and chat with a database.',
      link:
        'https://colab.research.google.com/drive/1IrMt3VLtukJK6mIXu2uzx_vu692rExIr?usp=sharing',
    },
    {
      id: 9,
      name: 'Evaluation Metrics',
      description:
        'This notebook demonstrates how to run evaluation tests on BerryGPT and get metrics.',
      link:
        'https://colab.research.google.com/drive/1Rt9NnXQhuBI1Oeznm0mc-_ORtfjS0OJE',
    },
    {
      id: 10,
      name: 'TrafficAnalysis',
      description:
        'This notebook analyzes traffic by detecting familiar objects and also captions the traffic scene.',
      link:
        'https://colab.research.google.com/drive/1x-DD_f4TTp1yimZVmdyNddLmF35GUH62',
    },
    /* {
      id: 5,
      name: 'VectorizeImages',
      description: 'This notebook vectorizes all images in a BerryDB database',
      link:
        'https://colab.research.google.com/drive/1u-RizE-t0etjiKkPOToh6uqXI02mt-NJ',
    },
    {
      id: 6,
      name: 'FindSimilarImages',
      description:
        'This notebook finds similar images in a BerryDB database for a given image',
      link:
        'https://colab.research.google.com/drive/1Yp--Sf6dZ31SM8Dqw9g6XPRLmCp-kBek',
    }, */
  ];

  return (
    <div className="mt-5 d-flex justify-content-center reduced_list_gap">
      <Card style={{ width: '90%', padding: '30px 15px', marginTop: '20px' }}>
        <CardContent>
          <h4
            className={styles['notebooks-title']}
            style={{ fontSize: '21px' }}
          >
            {'BerryDB SDK and Documentation'}
          </h4>
          <div className={styles['notebook__list']}>
            <div className={styles['info-item-container']}>
              <div className={styles['info-item']}>
                <div className={styles['info-item__img']}>
                  <img className={styles['info-item__img']} src={PythonIcon} />
                </div>
                <div className={styles['info-item__text']}>
                  BerryDB's Python SDK
                </div>
                <div className={styles['info-item__icon']}>
                  <a
                    href={'https://pypi.org/project/berrydb/'}
                    target={'_blank'}
                  >
                    <OpenInNewIcon fontSize={'inherit'} />
                  </a>
                </div>
              </div>

              <div className={styles['info-item']}>
                <div className={styles['info-item__img-icon']}>
                  <DescriptionIcon fontSize={'inherit'} />
                </div>
                <div className={styles['info-item__text']}>
                  Python SDK documentation
                </div>
                <div className={styles['info-item__icon']}>
                  <a
                    href={'https://docs.berrydb.io/python-sdk'}
                    target={'_blank'}
                  >
                    <OpenInNewIcon fontSize={'inherit'} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <h4
            className={styles['notebooks-title']}
            style={{ fontSize: '21px' }}
          >
            {CUSTOM_MESSAGES.NOTEPAD_HEADING}
          </h4>
          <div className={styles['notebook__list']}>
            {notebookList.map(nb => (
              <div className={styles['item-container']}>
                <span className={styles['index']}>{nb.id}</span>
                <span className={styles['item']}>
                  <span className={styles.name}>{nb.name}</span>:{' '}
                  {nb.description}
                  <a href={nb.link} target="_blank">
                    <OpenInNewIcon className={styles.icon} />
                  </a>
                </span>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Notepad;
