export const GET_OTP_REQUESTED = 'otp/OTP_REQUESTED';
export const GET_OTP_SUCCESS = 'otp/OTP_SUCCESS';
export const GET_OTP_FAILURE = 'otp/OTP_FAILURE';

export const SET_PWD_REQ = 'REGISTER/SET_PWD_REQ';
export const SET_PWD_SUCCESS = 'REGISTER/SET_PWD_SUCCESS';
export const SET_PWD_FAILURE = 'REGISTER/SET_PWD_FAILURE';

export const VERIFY_OTP_REQUESTED = 'otp/VERIFY_OTP_REQUESTED';
export const VERIFY_OTP_SUCCESS = 'otp/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'otp/VERIFY_OTP_FAILURE';

export const LOGIN_REQUESTED = 'otp/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'otp/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'otp/LOGIN_FAILURE';

export const GET_USER_REQUESTED = 'REGISTER/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'REGISTER/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'REGISTER/GET_USER_FAILURE';

export const SAVE_USER_REQUESTED = 'REGISTER/SAVE_USER_REQUESTED';
export const SAVE_USER_SUCCESS = 'REGISTER/SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'REGISTER/SAVE_USER_FAILURE';

export const LABEL_STUDIO_AUTH = 'labelStudio/AUTH';
export const LABEL_STUDIO_AUTH_FAILURE = 'labelStudio/FAILURE';

export const LABEL_STUDIO_LOGOUT = 'labelStudio/LOGOUT';
export const LABEL_STUDIO_LOGIN = 'labelStudio/LOGIN';

export const GET_USER_DATA = 'otp/GET_USER_DATA';
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  registerUser: null,
  userDetailsOnLoad: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OTP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case SET_PWD_REQ: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SET_PWD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_PWD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case VERIFY_OTP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case VERIFY_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_USER_DATA: {
      return {
        registerUser: action.payload
      };
    }
    case LOGIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        login_loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        login_loading: false
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        login_loading: false
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LABEL_STUDIO_AUTH: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      };
    }
    case LABEL_STUDIO_LOGOUT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      };
    }
    case LABEL_STUDIO_LOGIN: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      };
    }
    case LABEL_STUDIO_AUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userDetailsOnLoad: action.result
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SAVE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SAVE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const sendotp = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [GET_OTP_REQUESTED, GET_OTP_SUCCESS, GET_OTP_FAILURE],
    promise: (client) => client.post('sendotp', {
      data,
      headers
    })
  };
};

export const setPassword = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [SET_PWD_REQ, SET_PWD_SUCCESS, SET_PWD_FAILURE],
    promise: (client) => client.post('setPassword', {
      data,
      headers
    })
  };
};

export const verifyotp = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [VERIFY_OTP_REQUESTED, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE],
    promise: (client) => client.post('verifyotp', {
      data,
      headers
    })
  };
};

export const getregisteruserinfo = (data) => {
  return {
    type: GET_USER_DATA,
    payload: data
  };
};

export const login = (data, isOtp) => {
  // const headers = { has_auth_token: 0 };
  return {
    types: [LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: (client) => client.post(`checklogin?isOtp=${isOtp}`, {
      data,
      // headers
    })
  };
};

export const getUserDetails = (email) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: (client) => client.get(`getUserDetails/details?email=${email}`)
  };
};

export const getAnnotationsAccessToken = (apiKey) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: (client) => client.get(`getAnnotationsAccessToken?apiKey=${apiKey}`)
  };
};

export const labelStudioAuth = (userEmail, userSurrogateId, orgName) => {
  return {
    types: [LABEL_STUDIO_AUTH, LABEL_STUDIO_AUTH_FAILURE],
    promise: (client) => client.get(`labelStudioAuth?email=${userEmail}&id=${userSurrogateId}&orgName=${orgName}`)
  };
};

/* export const labelStudioLogin = (payload) => {
  return {
    types: [LABEL_STUDIO_AUTH, LABEL_STUDIO_AUTH_FAILURE],
    promise: (client) => client.post('labelStudioLogin', {
      data: payload,
      setCookie: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': true
      },
    })
  };
};

export const labelStudioLoginHTML = (payload) => {
  return {
    types: [LABEL_STUDIO_AUTH, LABEL_STUDIO_AUTH_FAILURE],
    promise: (client) => client.get('labelStudioLogin', {
      data: payload,
      setCookie: true,
    })
  };
}; */

export const labelStudioLogout = () => {
  return {
    types: [LABEL_STUDIO_LOGOUT],
    promise: (client) => client.post('labelStudioLogout')
  };
};

export const saveFbGoogleUsers = (data) => {
  // const headers = { has_auth_token: 0 };
  return {
    types: [SAVE_USER_REQUESTED, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
    promise: (client) => client.post('saveFbGoogleUsers', {
      // headers,
      data
    })
  };
};
