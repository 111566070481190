/* eslint-disable */

const HOSTNAME_BASE = 'https://app.berrydb.io';

export default {
  // The URL we're connecting to
  // hostname: 'https://mapi.dev.onedine.com:4443',
  hostname: HOSTNAME_BASE || 'https://api.berrydb.io',
  appHostname: HOSTNAME_BASE || 'https://app.berrydb.io',
  // hostname: 'https://staging.vidberrys.com:',
  conversationHostname: 'http://192.168.29.130',
  chatHostname: `${HOSTNAME_BASE}/gpt`,
  mlBackendHostName: `${HOSTNAME_BASE}/ml-backend`,
  ports: {
    profile: 9093,
    berrydb: 9096,
    'label-studio': 8080,
    conversation: 8888,
    chat: 8888,
  },
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    login: '/api/token',
    register: '/api/account/register',
    reset_password: '/api/account/reset/password',
    fonts: '/api/fonts/getFontList/',
    checklogin: 'before/profile/user/login',
    saveFbGoogleUsers: 'before/profile/user/social',
    createDatabase: 'profile/database',
    getUserDetails: 'profile/user/get',
    getAnnotationsAccessToken: 'profile/user/annotations/access-token',
    getGenericTags: 'berrydb/database/tags',
    getApplicationTags: 'berrydb/database/application-tags',
    getChannelTags: 'berrydb/database/channel',
    saveApplicationTags: 'berrydb/database/tags',
    saveCategoryTags: 'berrydb/database/category',
    saveChannelTags: 'berrydb/database/channel',
    getCategoryTags: 'berrydb/database/category',
    getDatabaseList: 'profile/database/list',
    generateUploadTemplate: 'berrydb/populate/template',
    uploadFileData: 'berrydb/populate/upload-template',
    getItemDetails: 'berrydb/items/fetch',
    getItemDetailsByQuery: 'berrydb/items/query',
    getAnnotations: 'berrydb/items/annotations',
    getFilterData: 'berrydb/database/filterdata',
    downloadTemplate: 'berrydb/populate/template',
    deleteItemFromFilterList: 'berrydb/item/delete',
    globalDbSearch: 'berrydb/items/auto-complete',
    updateItemDetails: 'berrydb/item/update',
    getAllTags: 'berrydb/database/all-tags',
    getQueryResult: 'berrydb/items/query',
    storeFileData: '/app/parse-template',
    subscribeStomp: '/message/parse-template',
    deleteDatabase: 'profile/database',
    updateThumbnail: 'berrydb/item/update-thumbnail',
    getSchemasList: 'profile/schema/list',
    getSchemasDataTypes: 'profile/schema/data-types',
    getSchemaDetails: 'berrydb/schema',
    createSchemaMetadata: 'profile/schema',
    deleteSchema: 'profile/schema',
    saveSchemaDetails: 'berrydb/schema',
    getMetrics: 'profile/metrics',
    getAddedFilterApiData: 'berrydb/database/fields',
    updateDocuments: 'berrydb/documents/bulk',
    // Embedded Database List
    getEmbeddedDatabaseList: 'berrydb/database/embedded',
    backupRestore: 'profile/backups',
    getChatModelList: 'berrydb/chat/models',
    getAllPerspectivesForOrg: 'berrydb/chat/perspective',
    getPerspectivesForDatabase: 'berrydb/chat/perspective',
    createPerspective: 'berrydb/chat/perspective',
    deletePerspective: 'berrydb/chat/perspective',

    // OTP functionality endpoints
    sendotp: 'profile/user/generate/otp',
    // verifyotp: 'profile/user/verify/otp',
    setPassword: 'profile/user/set/password',
    // Label Studio login/sign-up
    labelStudioAuth: 'annotations/api/user/auth',
    // conversation (for external URL - do not use this for routes)
    conversation: '',
    // labelStudioLogin: 'label-studio/user/login',
    labelStudioLogout: 'annotations/api/user/logout',
    chatEmbed: 'chat/embed',
    chatDelete: 'chat',
    chatQuestion: 'chat',
    chatMessages: 'chat/messages',
    chatMessages: 'chat/messages',
    mlModels: 'models',
    // mlModels: 'annotations/api/ml/models',
    fetchSchemaMetadataById: 'profile/schema',

    // Pipelines
    getPipelinesExtractTypes: 'profile/semantics/models',
  },
};
