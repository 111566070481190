/* eslint-disable */

import React, { useEffect, useState } from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { IconButton, Menu } from '@mui/material';
import { MenuItem, Tooltip } from '@material-ui/core';
import { APIConfig } from '../../constants';
import classes from './ContextComponent.module.scss';
import OpenInNew from '@mui/icons-material/OpenInNew';

const ContextComponent = ({ contexts, metadata }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [objectIds, setObjectIds] = useState([]);
  const [displayStrings, setDisplayStrings] = useState([]);
  const [databaseId, setDatabaseId] = useState(-1);
  const [displayString, setDisplayString] = useState('');
  const HOSTNAME = APIConfig.appHostname;

  useEffect(() => {
    console.log('metadata', metadata);
    let currentDisplayString = '';
    if (
      metadata &&
      metadata.fieldsByDataType &&
      metadata.fieldsByDataType.displayTextField &&
      metadata.fieldsByDataType.displayTextField.length
    ) {
      currentDisplayString = metadata.fieldsByDataType.displayTextField[0];
      setDisplayString(currentDisplayString);
    }
    if (contexts && contexts.length) {
      try {
        setDatabaseId(
          JSON.parse(JSON.parse(contexts[0]))['_meta']['databaseId']
        );
      } catch (e) {
        console.log(e);
      }
      contexts.forEach(context => {
        try {
          const parsedJson = JSON.parse(JSON.parse(context));
          console.log('parsedJson', parsedJson);
          const c = parsedJson['_meta']['objectId'];
          objectIds.push(c);
          try {
            console.log(displayString, 'displayString');
            const cd = getValueByPath(parsedJson, currentDisplayString);
            if (cd) displayStrings.push(cd);
            else {
              displayStrings.push(c);
            }
          } catch (e) {
            displayStrings.push(c);
          }
        } catch (e) {
          displayStrings.push(context);
          objectIds.push('');
        }
      });
    }
  }, []);

  const getValueByPath = (obj, path) => {
    if (!(obj && path)) {
      return '';
    }
    const keys = (path || '')
      .replace('[0].', '.0.')
      .split('.')
      .filter(Boolean);
    let current = obj;

    for (const key of keys) {
      if (current && (current.hasOwnProperty(key) || current[key])) {
        current = current[key];
      } else {
        return '';
      }
    }

    return typeof current === 'string' ? current : '';
  };

  const convertFormatToBracketNotation = str => {
    return str.replace(/\{(\w+)\}/g, '["$1"]');
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = index => {
    const objectId = objectIds[index];
    if (objectId) {
      const url = `${HOSTNAME}/detail/${databaseId}/${objectIds[index]}`;
      window.open(url, '_blank');
    }
    handleMenuClose();
  };

  const iconStyle = {
    color: '#3f51b5',
  };

  return (
    <span>
      <Tooltip title="Retrieve Context">
        <IconButton
          style={iconStyle}
          aria-label="play audio"
          size="small"
          onClick={handleMenuOpen}
        >
          <LibraryBooksIcon />
        </IconButton>
      </Tooltip>

      {/* Menu anchored to the play button */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            borderRadius: 10,
            minWidth: 350,
            maxWidth: 450,
            maxHeight: 500,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          },
        }}
      >
        <MenuItem disabled>
          <div className={classes['context-heading']}>Context</div>
        </MenuItem>
        {displayStrings.map((context, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(index)}>
            <div className={classes['context-item']}>
              <div className={classes['context-item__num']}>{`${index +
                1}.`}</div>
              <div className={classes['context-item__text']}>{context}</div>
              <div className={classes['context-item__icon']}>
                <OpenInNew fontSize={'inherit'} color={'primary'} />
              </div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};

export default ContextComponent;
